@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Krona+One&family=Lato:wght@300;400;700&display=swap');

/* NT Fabulous si tienes el archivo local */
@font-face {
  font-family: 'NT Fabulous';
  src: url('./styles/NTFabulous.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


h1, h2, h3 {
  font-family: 'Krona One', sans-serif;
}
.logo {
  font-family: 'NT Fabulous', sans-serif;
}

/* Elimina cualquier desplazamiento lateral */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Evita el scroll horizontal */
}

section {
  overflow-x: hidden; /* Protege secciones individuales del desbordamiento */
}

img {
  max-width: 100%;
  height: auto; /* Garantiza que las imágenes no se desborden */
}

.menu-link {
  position: relative;
  color: #264653; /* Color inicial */
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

.menu-link:hover {
  color: #2A9D8F; /* Color al hacer hover */
}

.menu-link::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  background: #2A9D8F;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.menu-link:hover::after {
  width: 100%;
  left: 0;
}

/* Mejoras de accesibilidad */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Mejoras para formularios */
input:focus,
textarea:focus,
button:focus {
  @apply outline-none ring-2 ring-primary ring-opacity-50;
}